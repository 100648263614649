<template>
  <div>
    <h1>This page is currently under construction...</h1>
  </div>
</template>

<script>
export default {
  name: "UnderConstruction",
  title: "Admin - Under Construction",
  mounted() {
    this.$store.dispatch("search/clear");
  },
  beforeCreate() {
    this.$store.dispatch("search/clear");
    this.$store.dispatch("transaction/clearReservation");
  }
};
</script>
